<app-spinner [status]="status"></app-spinner>

<ng-container *ngIf="this.status === 'hidden'">
    <div class="row" *ngIf="data">
        <div *ngFor="let col of data.firstRow; let i=index;" class="text-center {{col.size}}"
             [ngClass]="{'border-right': i < data.firstRow.length-1}">
            <div class="py-2" [ngClass]="{'box-hover-dark rounded': col.hasOwnProperty('url')}"
                 (click)="dbm.show(col.url, 'Dettaglio')">
                <img [src]="col.image" height="70px"/>
                <p class="lead" [innerHTML]="col.title"></p>
                <p [innerHTML]="col.subtitle"></p>
            </div>
        </div>
    </div>
    <hr>
    <div class="row" *ngIf="data">
        <div *ngFor="let col of data.secondRow; let i=index;" class="{{col.size}}"
             [ngClass]="{'border-right': i < data.secondRow.length-1}">
            <div class="col-12 p-0">
                <div *ngFor="let row of col.rows; let j=index;"
                     [ngClass]="{'mb-3': j < col.rows.length-1}">
                    <div class="py-2 d-flex align-items-center" [ngClass]="{'box-hover-dark rounded': row.hasOwnProperty('url')}"
                         (click)="dbm.show(row.url, 'Dettaglio')">
                        <img [src]="row.image" width="45px" class="d-inline-flex ml-2"/>
                        <div class="d-inline-block pl-2">
                            <p style="font-size: 16px"  class="lead" [innerHTML]="row.title"></p>
                            <p [innerHTML]="row.subtitle"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>