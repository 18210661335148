<div class="card bg-gradient-light">
	<div class="card-header" *ngIf="!hideHeader">
		<div class="row">
			<div class="col-lg-auto col-12">
				<h1 class="display-6 mb-0 mb-2" translate>Timeline</h1>
			</div>
			<div class="col-lg-3 col-12">
				<input
					(selected)="loadNewDate($event?.start, $event?.end)"
					[(ngModel)]="selectedDate"
					class="form-control form-control-sm"
					daterangepicker
					id="date"
					type="text"
				/>
			</div>
			<div class="col-auto">
				<button (click)="showAll()" class="btn btn-primary btn-sm">Mostra Tutti</button>
			</div>
			<div class="col-auto ml-auto">
				<button (click)="downloadReportExel()" class="btn btn-dark btn-sm">Scarica Report</button>
			</div>
			<div *show="['agent']" class="col-auto justify-content-lg-end">
				<button
					(click)="showReportModal()"
					class="btn btn-success btn-sm float-right"
					translate
					type="button"
				>
					Aggiungi Report
				</button>
			</div>
		</div>
	</div>
	<div class="card-body">
		<ngx-spinner
			[fullScreen]="false"
			bdColor="rgba(0, 0, 0, 0.8)"
			color="#fff"
			name="timeline"
			size="default"
			type="square-jelly-box"
		></ngx-spinner>
		<div class="row">
			<div class="col-7">
				<span *ngIf="reports?.length <= 0" class="text-muted">No reports</span>
				<div *ngIf="reports?.length > 0" class="timeline">
					<ng-container *ngFor="let date of dates">
						<div class="time-label lead">
							<span class="bg-gradient-success">{{ date | amDateFormat : localDateFormat }}</span>
						</div>
						<div *ngFor="let report of reports" [ngClass]="{ 'd-none': report.date != date }">
							<i
								*ngIf="report.date == date"
								[ngClass]="{
									'fa-comment bg-gradient-info': report.type === 'report',
									'fa-map bg-gradient-success': report.type === 'visit',
									'fa-phone bg-gradient-warning': report.type === 'call'
								}"
								class="fas"
							></i>
							<div *ngIf="report.date == date" class="elevation-1 timeline-item">
								<h3 class="timeline-header pb-4 pb-lg-0">
									<strong>
										<em>{{ report?.from }}</em>
									</strong>
									<span class="float-right">{{ report?.category }}</span>
								</h3>
								<div class="timeline-body">
									<span class="text-muted">
										<app-rich-text-truncate
											[charLimit]="10000"
											[text]="report?.text"
										></app-rich-text-truncate>
									</span>

									<ng-container *ngIf="report.type === 'visit'">
										<div
											*ngFor="let item of report.json_data | keyvalue"
											class="bg-gradient-white rounded p-2 mb-2 border"
										>
											<div class="text-md font-weight-bold">{{ item.key }}</div>
											<div>{{ item.value }}</div>
										</div>

										<div
											*ngIf="report.json_data && report.json_data.length === 0"
											class="text-muted font-italic"
										>
											Non ci sono note
										</div>

										<p *ngIf="report.affiliato === '1'" class="mt-2">
											<i class="fas fa-check-circle text-success"></i>
											Affiliato
										</p>
										<p *ngIf="report.attivita_chiusa === '1'" class="mt-2">
											<i class="fas fa-check-circle text-danger"></i>
											Attivita chiusa
										</p>

										<ng-container *ngIf="report?.documents.length > 0">
											<hr />
											<p class="text-italic" translate>Foto del negozio:</p>
											<ul>
												<li *ngFor="let document of report?.documents; let i = index">
													<app-documents-modal #showModal [image]="true"></app-documents-modal>
													<div
														(click)="
															showModal.showDocument(
																environment.baseUrl +
																	'/visite/get-picture?visit_id=' +
																	report.id +
																	'&id=' +
																	document.id,
																true
															)
														"
														class="link"
													>
														Foto {{ i + 1 }}
													</div>
												</li>
											</ul>
										</ng-container>

										<button
											(click)="showModalQuestions(report.id)"
											class="btn btn-primary btn-block"
										>
											Informazioni complete del questionario
										</button>
									</ng-container>
								</div>
								<div class="timeline-footer border-top">
									<div class="row align-items-center justify-content-between">
										<div class="col">
											<p class="mb-0 text-muted mr-2" style="font-size: 14px">
												<i class="far fa-clock mr-2 text-info"></i>
												<span>{{ report?.datetime | amDateFormat : 'HH:mm DD/MM/YYYY' }}</span>
											</p>
										</div>
										<ng-container *ngIf="report.type === 'report'">
											<div *show="['operator', 'agent']" class="col text-right">
												<button
													(click)="showReportModal(report)"
													*ngIf="currentUser == report?.user_id"
													class="btn btn-link btn-sm"
												>
													<i class="fa fa-edit text-muted"></i>
												</button>
												<button
													(confirm)="deleteReport(report.id)"
													*ngIf="currentUser == report?.user_id"
													[swal]="deleteDocumentOptions"
													class="btn btn-link btn-sm"
												>
													<i class="fa fa-trash text-danger"></i>
												</button>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="col-5">
				<div class="sticky-top">
					<div class="card" style="width: 100%">
						<div class="card-header lead display-6">Foto del Partner</div>
						<ngx-spinner
							[fullScreen]="false"
							*ngIf="isImageLoading"
							bdColor="rgba(0, 0, 0, 0.8)"
							color="#fff"
							name="photoTime"
							size="default"
							type="square-jelly-box"
						></ngx-spinner>
						<div style="height: 600px">
							<img
								*ngIf="!isImageLoading && currentVisitImage"
								[src]="currentVisitImage.filePath"
								style="height: 100%; width: 100%; object-fit: cover"
								class="card-img-top"
								alt="Immagine partner"
							/>
						</div>
						<div class="card-body">
							<!-- <h4 class="card-title">Nome negozio</h4>
                            <p class="card-text my-5">Dati visita</p> -->
							<div class="d-flex">
								<div class="col-4">
									<button
										class="btn btn-sm btn-primary"
										(click)="previousImage()"
										[disabled]="currentImageIndex === 0"
									>
										<i class="fas fa-arrow-left"></i>
										Precedente
									</button>
								</div>
								<div class="col-4 text-center items-center">
									Foto {{ currentImageIndex + 1 }} di {{ visitImages.length }}
								</div>
								<div class="col-4">
									<button
										class="btn btn-sm btn-primary float-right"
										(click)="nextImage()"
										[disabled]="currentImageIndex === visitImages.length - 1"
									>
										Successiva
										<i class="fas fa-arrow-right"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- modale -->
<ng-template #questionsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title clamp-title">Informazioni complete del questionario</h4>
		<button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<app-spinner *ngIf="!dealerData" status="loading"></app-spinner>

	<div class="modal-body" *ngIf="dealerData">
		<!-- Anagrafica -->
		<div class="card my-3" *ngIf="dealerData.type === 'dealer'">
			<div class="card-header bg-info">
				<h6 class="mb-0 font-weight-bold text-uppercase">Anagrafica Dealer</h6>
			</div>
			<div class="card-body">
				<dl class="row mb-0">
					<div class="col-md-6">
						<dl class="row mb-0">
							<dt class="col-sm-5 pr-0">Ragione Sociale:</dt>
							<dd class="col-sm-7 pl-0">{{ dealerData.dealerInfo.company }}</dd>
							<dt class="col-sm-5 pr-0">Nome:</dt>
							<dd class="col-sm-7 pl-0">{{ dealerData.dealerInfo.nome }}</dd>

							<dt class="col-sm-5 pr-0">Cognome:</dt>
							<dd class="col-sm-7 pl-0">{{ dealerData.dealerInfo.cognome }}</dd>

							<dt class="col-sm-5 pr-0">Attività chiusa:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.is_activity_closed ? 'Si' : 'No' }}
							</dd>

							<dt class="col-sm-5 pr-0">Posizione:</dt>
							<dd class="col-sm-7 pl-0">
								<b>Indirizzo:</b>
								{{ dealerData.dealerInfo.indirizzo }}
								<br />
								<b>Civico:</b>
								{{ dealerData.dealerInfo.civico }}
								<br />
								<b>Comune:</b>
								{{ dealerData.dealerInfo.localita }}
								<br />
								<b>CAP:</b>
								{{ dealerData.dealerInfo.cap }}
							</dd>
							<dt class="col-sm-5 pr-0">Operatori:</dt>
							<dd class="col-sm-7 pl-0">
								<ul class="p-0">
									<li *ngFor="let brand of dealerData.dealerInfo.brands">
										{{ brand }}
									</li>
								</ul>
							</dd>
						</dl>
					</div>
					<div class="col-md-6">
						<dl class="row mb-0">
							<dt class="col-sm-5 px-0">Telefono:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.dealerInfo.telefono }}
							</dd>

							<dt class="col-sm-5 px-0">Cellulare:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.dealerInfo.cellulare }}
							</dd>

							<dt class="col-sm-5 px-0">Email:</dt>
							<dd class="col-sm-7 pl-0">{{ dealerData.dealerInfo.email }}</dd>

							<dt class="col-sm-5 px-0">Giorni di chiusura:</dt>
							<dd class="col-sm-7 pl-0">
								<ul class="p-0">
									<li *ngFor="let day of dealerData.dealerInfo.closing_days">
										{{ day }}
									</li>
								</ul>
							</dd>
							<dt class="col-sm-5 px-0">Materiale esposto:</dt>
							<dd class="col-sm-7 pl-0">
								<ul class="p-0">
									<li *ngFor="let ad of dealerData.dealerInfo.material_exposed">
										{{ ad }}
									</li>
								</ul>
							</dd>

							<dd></dd>
						</dl>
					</div>
				</dl>
			</div>
		</div>
		<div class="card my-3" *ngIf="dealerData.type === 'prospect'">
			<div class="card-header bg-info">
				<h6 class="mb-0 font-weight-bold text-uppercase">Anagrafica Dealer</h6>
			</div>
			<div class="card-body">
				<dl class="row mb-0">
					<div class="col-md-6">
						<dl class="row mb-0">
							<dt class="col-sm-5 pr-0">Ragione Sociale:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.prospectInfo.company }}
							</dd>

							<dt class="col-sm-5 pr-0">Attività chiusa:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.is_activity_closed ? 'Si' : 'No' }}
							</dd>
						</dl>
					</div>
					<div class="col-md-6">
						<dl class="row mb-0">
							<dt class="col-sm-5 px-0">Comune:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.prospectInfo.localita }}
							</dd>

							<dt class="col-sm-5 px-0">Provincia:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.prospectInfo.provincia }}
							</dd>
						</dl>
					</div>
				</dl>
			</div>
		</div>

		<!-- Domande e Risposte -->
		<div class="card">
			<div class="card-header bg-info">
				<h6 class="mb-0 font-weight-bold text-uppercase">Questionario</h6>
			</div>
			<div class="card-body">
				<div class="mb-3" *ngFor="let item of dealerData.json_data | keyvalue">
					<h6 class="font-weight-bold">{{ item.key }}</h6>
					<p>{{ item.value }}</p>
				</div>
			</div>
		</div>
	</div>

	<!--        <div class="modal-footer">-->
	<!--            <button type="button" class="btn btn-info"  (click)="downloadQuestionExcel()">Scarica questionario</button>-->
	<!--        </div>-->
</ng-template>
<!--  -->

<ng-template #reportFormTemplate let-modal>
	<div class="modal-header">
		<h4 class="modal-title clamp-title" translate>Add Report</h4>
		<button (click)="modal.close('Ok click')" aria-label="Close" class="close" type="button">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div [ngBusy]="busy" class="modal-body">
		<form (ngSubmit)="saveReport()" [formGroup]="reportForm">
			<div class="form-row">
				<div class="col-12">
					<label for="category" translate>Category</label>
				</div>
				<div class="col-12 col-lg form-group">
					<ng-select
						(search)="loadCategories($event?.term)"
						[items]="categories"
						[loading]="categoriesLoading"
						[searchable]="true"
						bindLabel="name"
						bindValue="id"
						formControlName="category_id"
						id="category"
					></ng-select>
					<val-errors controlName="category_id">
						<ng-template valError="required">
							<span translate>Category is required</span>
						</ng-template>
					</val-errors>
				</div>
				<div class="col-12 col-lg form-group">
					<button
						(click)="showCategoryModal()"
						class="btn btn-secondary btn-block"
						translate
						type="button"
					>
						Add New Category
					</button>
				</div>
				<div class="col-12 form-group">
					<label for="note" translate>Note</label>
					<editor
						[init]="{ base_url: '/tinymce', suffix: '.min' }"
						formControlName="note"
						id="note"
					></editor>
					<val-errors controlName="note">
						<ng-template valError="required">
							<span translate>Note is required</span>
						</ng-template>
					</val-errors>
				</div>
				<div class="col-12">
					<button [disabled]="reportForm.invalid" class="btn btn-success btn-block" type="submit">
						<i class="fa fa-check-circle mr-2"></i>
						<span translate>Save</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</ng-template>
<ng-template #categoryFormTemplate let-modal>
	<div class="modal-header">
		<h4 class="modal-title clamp-title" translate>Add Category</h4>
		<button (click)="modal.close('Ok click')" aria-label="Close" class="close" type="button">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div [ngBusy]="busyCategory" class="modal-body">
		<form (ngSubmit)="saveReportCategory()" [formGroup]="categoryForm">
			<div class="form-row">
				<div class="col-12 form-group">
					<label for="name" translate>Name</label>
					<input class="form-control" formControlName="name" id="name" type="text" />
					<val-errors controlName="name">
						<ng-template valError="required">
							<span translate>Name is required</span>
						</ng-template>
					</val-errors>
				</div>
				<div class="col-12">
					<button [disabled]="categoryForm.invalid" class="btn btn-success btn-block" type="submit">
						<i class="fa fa-check-circle mr-2"></i>
						<span translate>Save</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</ng-template>
