<app-master-header
    (onChange)="this.updateUrls($event.operatore)"
    [operatori]="true"
    cardClass="bg-gradient-light"
    headerBackground="assets/img/italy.jpg"
    title="Gestione Partner"
>
</app-master-header>

<ul #nav="ngbNav" class="nav-tabs mg-tabs" ngbNav>
    <li ngbNavItem>
        <a ngbNavLink>Partner</a>
        <ng-template ngbNavContent>
            <button
                (click)="showFileUpload()"
                *show="['master']"
                class="btn btn-sm mb-2 btn-info"
            >
                <i class="fa fa-upload mr-2"></i>
                Carica Partner
            </button>
            <app-master-card
                bodyClass="p-0"
                image="assets/img/flats/ribbon.png"
                title="Anagrafiche Partner"
            >
                <data-builder-table [url]="urlClassificaItalia"></data-builder-table>
            </app-master-card>

            <ng-template #fileUploadModal let-modal size="xl">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">
                        Caricamento Partner
                    </h4>
                    <button
                        (click)="modal.dismiss('Cross click')"
                        aria-label="Close"
                        class="close"
                        type="button"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-0">
                    <app-spinner status="loading" *ngIf="importPartners"></app-spinner>
                    <div class="form-group p-4" *ngIf="importLogs.length === 0 && !importPartners">
                        <label for="file">File</label>
                        <div class="custom-file" id="file">
                            <input
                                (change)="uploadFile($event)"
                                aria-describedby="fileHelp"
                                class="custom-file-input"
                                id="exampleInputFile"
                                type="file"
                            />
                            <label class="custom-file-label" for="exampleInputFile">
                                Seleziona il file
                            </label>
                        </div>
                    </div>
                    <div *ngIf="importLogs.length > 0" class="table-responsive" style="max-height: 600px;">
                        <table class="table table-sm">
                            <tr>
                                <th>Tipo</th>
                                <th>Log</th>
                            </tr>
                            <tr *ngFor="let log of importLogs">
                                <td class="text-center">
                                    <i class="fa"
                                       [ngClass]="{
                                'fa-info-circle text-info': log.type === 'info',
                                'fa-check-circle text-success': log.type === 'success',
                                'fa-times-circle text-danger': log.type === 'error'
                            }"></i>
                                </td>
                                <td>{{ log.message }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </li>
    <li *show="['master', 'masteragent']" ngbNavItem>
        <a ngbNavLink translate>Key Account</a>
        <ng-template ngbNavContent>
            <app-master-card
                bodyClass="p-0"
                image="assets/img/flats/employee.png"
                title="Anagrafiche District"
            >
                <data-builder-table [url]="urlClassificaAgenti"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li *show="['master']" ngbNavItem>
        <a ngbNavLink translate>Area Manager</a>
        <ng-template ngbNavContent>
            <app-master-card
                bodyClass="p-0"
                image="assets/img/flats/global-network.png"
                title="Anagrafiche Regional"
            >
                <data-builder-table
                    [url]="urlClassificaAreaManager"
                ></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>

    <li *show="['master', 'masteragent']" ngbNavItem>
        <a ngbNavLink translate>Formazione - Trade MKTG Globale</a>
        <ng-template ngbNavContent>
            <app-master-card
                bodyClass="p-0"
                image="assets/img/flats/global-network.png"
                title="Formazione"
            >
                <data-builder-table
                    [url]="urlClassificaFormazione"
                ></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li *show="['master', 'masteragent']" ngbNavItem>
        <a ngbNavLink translate>Formazione - Trade MKTG Ridotto</a>
        <ng-template ngbNavContent>
            <app-master-card
                bodyClass="p-0"
                image="assets/img/flats/global-network.png"
                title="Trade Marketing"
            >
                <data-builder-table
                    [url]="urlClassificaTradeMarketing"
                ></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
