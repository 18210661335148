<div [ngBusy]="busy" class="row" style="padding: 40px">
  <div class="container-fluid">
    <div class="card w-100 pb-0">
      <div
        class="card-header border-bottom pb-0 d-flex justify-content-center align-items-center"
      >
        <h2 class="lead display-5 pb-2 pt-2" style="font-weight: bold">
          Scheda Anagrafica Utente Formazione
        </h2>
      </div>
      <div class="card-body bg-gradient-info">
        <div class="container-fluid">
          <ngx-spinner
            [fullScreen]="false"
            bdColor="rgba(0, 0, 0, 0.8)"
            color="#fff"
            name="userDetails"
            size="default"
            type="square-jelly-box"
          >
            <p style="color: white">Caricamento...</p>
          </ngx-spinner>
          <div class="info-text">
            <p><i class="fa fa-user-circle"></i> Ragione Sociale</p>
            <span class="display-6" style="font-weight: bold">{{
              operator.ragsociale
            }}</span>
          </div>
          <div class="row">
            <div class="col-sm-4 p-4">
              <div class="info-text">
                <p><i class="fa fa-user"></i> Login Code</p>
                <span class="lead">{{ operator.username }} </span>
              </div>
            </div>
            <div class="col-sm-4 p-4">
              <div class="info-text">
                <p><i class="fa fa-mobile"></i> Cellulare</p>
                <span class="lead">{{ operator.cellulare }} </span>
              </div>
            </div>
            <div class="col-sm-4 p-4">

                <div class="info-text">
                    <p><i class="fa fa-envelope"></i> E-Mail</p>
                    <span class="lead" *ngIf="!edit.email"
                    >{{ operator.email }}
                        <i
                            (click)="edit.email = true"
                            class="fas fa-pencil-alt cursor-hand float-right text-md"
                        ></i>
                </span>
                    <i
                        class="fas fa-circle-notch fa-spin"
                        *ngIf="this.loading.email"
                    ></i>
                    <div
                        *ngIf="edit.email && !this.loading.email"
                        class="input-group input-group-sm"
                    >
                        <input
                            [(ngModel)]="operator.email"
                            class="form-control"
                            type="text"
                        />
                        <div class="input-group-append">
                            <button
                                (click)="edit.email = false"
                                class="btn btn-light"
                                type="button"
                            >
                                <i class="fas fa-times mr-0"></i>
                            </button>
                            <button
                                (click)="
                        saveInfo({ email: operator.email }, 'email')
                      "
                                class="btn btn-light"
                                type="button"
                            >
                                <i class="fas fa-check mr-0"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *show="['master']">
      <button class="btn btn-success" (click)="openModal()">
        Aggiungi Regione
        <span><i class="fa fa-plus text-white ml-1"></i></span>
      </button>
      <ng-container *ngIf="regioni">
        <div class="card mt-3">
          <div class="rounded-lg shadow-sm table-responsive p-0">
            <table class="table table-striped mb-0">
              <thead class="bg-dark">
                <tr>
                  <th>#</th>
                  <th>Regione</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let regione of regioni">
                  <td>{{ regione.region_id }}</td>
                  <td>{{ regione.regione }}</td>
                  <td>
                    <button
                      class="btn btn-block"
                      (click)="openConfirmDeleteModal(regione)"
                    >
                      <i class="fas fa-trash text-danger mr-0"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #regioneTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title">Assegna Regione</h4>
    <button
      (click)="closeModal()"
      aria-label="Close"
      class="close"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-spinner
      [fullScreen]="false"
      bdColor="rgba(0, 0, 0, 0.8)"
      color="#fff"
      name="addRegione"
      size="default"
      type="square-jelly-box"
    >
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <label>Regione</label>
    <app-state-select (onChange)="changeRegione($event)"></app-state-select>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" (click)="saveRegione()">Salva</button>
  </div>
</ng-template>

<ng-template #confirmDeleteModal let-modal>
  <ngx-spinner
    [fullScreen]="false"
    bdColor="rgba(0, 0, 0, 0.8)"
    color="#fff"
    name="deleteModalSpinner"
    size="default"
    type="square-jelly-box"
  >
    <p style="color: white">Caricamento...</p>
  </ngx-spinner>
  <div class="modal-header">
    <h5 class="modal-title">Elimina Regione</h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Sei sicuro di voler procedere?</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      Annulla
    </button>
    <button type="submit" class="btn btn-danger" (click)="confirmDelete()">
      Elimina
    </button>
  </div>
</ng-template>
