<app-master-header
	[ngBusy]="busy"
	[title]="this.sales_agent"
	cardClass="bg-gradient-info"
	image="assets/img/flats/agent.png"
>
	<div class="row align-items-end">
		<div class="col-lg-4 col-12">
			<label translate>Seleziona periodo</label>
			<input
				(selected)="setSelectedDateRange($event.start, $event.end)"
				[(ngModel)]="filterForm.dateRange"
				class="form-control"
				daterangepicker
				type="text"
			/>
		</div>
		<div class="col-lg-8 col-12">
			<label></label>
			<button
				[ladda]="this.laddaExcel"
				class="btn btn-dark float-right"
				(click)="this.downloadExcel()"
			>
				<i class="fa fa-download"></i>
				Excel
			</button>
		</div>
	</div>
</app-master-header>

<div class="card">
	<div class="card-body">
		<div *ngIf="global" class="row">
			<div *ngIf="defaultView" class="col-12 col-md-4 col-xl-2">
				<div class="icon-block">
					<img class="img-fluid" src="/assets/img/flats/online-store.png" />
					<span class="title">{{ global.today_visits }}</span>
					<span class="description">Visite di oggi</span>
				</div>
			</div>
			<div class="col-12 col-md-4 col-xl-2">
				<div class="icon-block">
					<img class="img-fluid" src="/assets/img/flats/calendar_2.png" />
					<span class="title">{{ global.period_visits }}</span>
					<span class="description">Visite nel periodo</span>
				</div>
			</div>
			<div class="col-12 col-md-4 col-xl-2">
				<div class="icon-block">
					<img class="img-fluid" src="/assets/img/flats/percentage.png" />
					<span class="title">{{ global.average_period_visits }}</span>
					<span class="description">Media visite</span>
				</div>
			</div>
			<div class="col-12 col-md-4 col-xl-2">
				<div class="icon-block">
					<img class="img-fluid" src="/assets/img/flats/settings_2.png" />
					<span class="title">{{ global.period_our_retailers }}</span>
					<span class="description">Visite uniche</span>
				</div>
			</div>
			<div class="col-12 col-md-4 col-xl-2">
				<div class="icon-block">
					<img class="img-fluid" src="/assets/img/flats/new.png" />
					<span class="title">{{ global.period_other_retailers }}</span>
					<span class="description">Visite uniche altri pdv</span>
				</div>
			</div>
			<div class="col-12 col-md-4 col-xl-2">
				<div class="icon-block">
					<img class="img-fluid" src="/assets/img/flats/internet.png" />
					<span class="title">{{ global.total_shops }}</span>
					<span class="description" translate>Negozi totali</span>
				</div>
			</div>
		</div>

		<div *ngIf="global" class="row mt-4">
			<div class="col-12 col-md-4 col-xl-2">
				<div class="icon-block">
					<img class="img-fluid" src="/assets/img/flats/map.png" />
					<span class="title">{{ global.period_cities }}</span>
					<span class="description">Comuni visitati</span>
				</div>
			</div>
			<div class="col-12 col-md-4 col-xl-2">
				<div class="icon-block">
					<img class="img-fluid" src="/assets/img/flats/placeholder.png" />
					<span class="title">{{ global.cities_with_shops }}</span>
					<span class="description" translate>Comuni con negozi</span>
				</div>
			</div>
			<div class="col-12 col-md-4 col-xl-2">
				<div class="icon-block">
					<img class="img-fluid" src="/assets/img/flats/global-network.png" />
					<span class="title">{{ global.covered_cities }}</span>
					<span class="description">Comuni totali</span>
				</div>
			</div>

			<div class="col-12 col-md-4 col-xl-2">
				<div class="d-flex">
					<i style="font-size: 52px" class="fa fa-circle text-success" aria-hidden="true"></i>
					<div class="icon-block d-flex flex-column">
						<span class="title ml-2">{{ global.cities_green }}</span>
						<span class="description ml-2" translate>Comuni Verdi Visitati</span>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4 col-xl-2">
				<div class="d-flex">
					<i style="font-size: 52px" class="fa fa-circle text-warning" aria-hidden="true"></i>
					<div class="icon-block d-flex flex-column">
						<span class="title ml-2">{{ global.cities_yellow }}</span>
						<span class="description ml-2" translate>Comuni Gialli Visitati</span>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4 col-xl-2">
				<div class="d-flex">
					<i style="font-size: 52px" class="fa fa-circle text-danger" aria-hidden="true"></i>
					<div class="icon-block d-flex flex-column">
						<span class="title ml-2">{{ global.cities_red }}</span>
						<span class="description ml-2" translate>Comuni Rossi Visitati</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<div class="col-12"></div>
</div>

<div class="row">
	<div class="col-12 col-xl-4">
		<app-master-card
			bodyClass="p-0"
			cardClass="bg-gradient-1"
			image="assets/img/flats/map.png"
			title="Analisi su provincia"
			[height]="50"
		>
			<table class="table mb-0">
				<thead>
					<tr>
						<th>Provincia</th>
						<th class="text-right">PDV Totali</th>
						<th class="text-right">PDV Visitati</th>
						<th class="text-right">%</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let row of provinces">
						<td>{{ row.name }}</td>
						<td class="text-right">{{ row.total_retailers }}</td>
						<td class="text-right">{{ row.our_retailer_visits }}</td>
						<td class="text-right">{{ row.percentage | percent }}</td>
					</tr>
				</tbody>
				<tfoot class="bg-gradient-light text-italic">
					<td colspan="4">
						<a
							[ladda]="this.laddaExcel"
							class="text-success cursor-hand"
							(click)="this.downloadExcelWithProvinces()"
						>
							<i class="fas fa-table"></i>
							Esporta Analisi Province in excel
						</a>
					</td>
				</tfoot>
			</table>
		</app-master-card>
		<app-master-card
			bodyClass="p-0"
			image="assets/img/flats/pie-chart.png"
			title="Programmazione Visite"
		>
			<ngx-spinner
				[fullScreen]="false"
				bdColor="rgba(0, 0, 0, 0.8)"
				color="#fff"
				name="calendar"
				size="default"
				type="square-jelly-box"
			>
				<p style="color: white">Caricamento...</p>
			</ngx-spinner>
			<full-calendar [options]="calendarOptions"></full-calendar>
		</app-master-card>
	</div>
	<div class="col-12 col-xl-4">
		<app-master-card
			bodyClass="py-0 px-3 h-0"
			image="assets/img/flats/gear.png"
			title="Riepilogo situazione"
			[height]="50"
		>
			<ul class="list-group list-group-unbordered">
				<li *ngIf="kpi && kpi.retailers_never_visited > 0" class="list-group-item border-0">
					<img class="mr-2" height="24px" src="../../../../assets/img/flats/online-store.png" />
					<a (click)="showRetailersNeverVisited()" class="float-right link">
						<i class="far fa-folder-open mr-1"></i>
						<em>Visualizza</em>
					</a>
					Ci sono
					<strong>{{ kpi.retailers_never_visited }}</strong>
					&nbsp;
					<span translate>negozi mai visitati</span>
				</li>
				<li *ngIf="global && global.retailers_started_to_be_productive > 0" class="list-group-item">
					<img class="mr-2" height="24px" src="../../../../assets/img/flats/settings_2.png" />
					<a
						(click)="showResultsDetail(modalResults, 'started-to-be-productive')"
						class="float-right link"
					>
						<i class="far fa-folder-open mr-1"></i>
						<em>Visualizza</em>
					</a>
					<strong>{{ global.retailers_started_to_be_productive }}</strong>
					negozi sono diventati produttivi
				</li>
				<li *ngIf="global && global.retailers_increased_sales > 0" class="list-group-item">
					<a (click)="showResultsDetail(modalResults, 'increased-sales')" class="float-right link">
						<i class="far fa-folder-open mr-1"></i>
						<em>Visualizza</em>
					</a>
					<img class="mr-2" height="24px" src="../../../../assets/img/flats/diagram.png" />
					<strong>{{ global.retailers_increased_sales }}</strong>
					negozi hanno aumentato la produttività
				</li>
				<li *ngIf="global && global.retailers_bought_plafond > 0" class="list-group-item">
					<a (click)="showResultsDetail(modalResults, 'bought-plafond')" class="float-right link">
						<i class="far fa-folder-open mr-1"></i>
						<em>Visualizza</em>
					</a>
					<img class="mr-2" height="24px" src="../../../../assets/img/flats/dollar.png" />
					<strong>{{ global.retailers_bought_plafond }}</strong>
					negozi hanno comprato il plafond
				</li>
				<li *ngIf="global && global.retailers_bought_sim > 0" class="list-group-item">
					<a (click)="showResultsDetail(modalResults, 'bought-sim')" class="float-right link">
						<i class="far fa-folder-open mr-1"></i>
						<em>Visualizza</em>
					</a>
					<img class="mr-2" height="24px" src="../../../../assets/img/flats/sim-card.png" />
					<strong>{{ global.retailers_bought_sim }}</strong>
					negozi hanno comprato le sim
				</li>
			</ul>
		</app-master-card>

		<app-master-card
			bodyClass="py-0 px-3"
			image="assets/img/flats/gear.png"
			title="Foto del partner"
			[height]="50"
		>
			<div *ngIf="currentVisitImage">
				<div class="py-3">
					<p>
						<b>Negozio:</b>
						{{ currentVisitImage.shop_name }}
					</p>
					<p>
						<b>Città:</b>
						{{ currentVisitImage.city }},
						{{ currentVisitImage.province }}
					</p>
					<p class="text-muted">
						Data: {{ currentVisitImage.date }} alle {{ currentVisitImage.time }}
					</p>
				</div>
				<div
					style="
						width: 100%;
						height: 300px;
						position: relative;
						overflow: hidden;
						margin-bottom: 15px;
					"
				>
					<img
						style="height: 100%; width: 100%; object-fit: contain"
						[src]="currentVisitImage.file_path"
						alt="Foto {{ currentImageIndex + 1 }}"
					/>
				</div>

				<div class="row mb-2">
					<div class="col-4">
						<button
							class="btn btn-sm btn-primary"
							(click)="previousImage()"
							[disabled]="currentImageIndex === 0"
						>
							<i class="fas fa-arrow-left"></i>
							Precedente
						</button>
					</div>
					<div class="col-4 text-center items-center">
						Foto {{ currentImageIndex + 1 }} di {{ visitImages.length }}
					</div>
					<div class="col-4">
						<button
							class="btn btn-sm btn-primary float-right"
							(click)="nextImage()"
							[disabled]="currentImageIndex === visitImages.length - 1"
						>
							Successiva
							<i class="fas fa-arrow-right"></i>
						</button>
					</div>
				</div>
			</div>
			<div *ngIf="!currentVisitImage">
				<div class="alert alert-info mt-4">
					<i class="fas fa-info-circle mr-2"></i>
					Nessuna foto disponibile
				</div>
			</div>
		</app-master-card>

		<app-master-card
			bodyClass="p-0"
			image="assets/img/flats/internet.png"
			title="Comuni più visitati"
		>
			<table class="table mb-0">
				<thead>
					<tr>
						<th>Provincia</th>
						<th>Comune</th>
						<th class="text-right">PDV Totali</th>
						<th class="text-right">Visite Totali</th>
						<th class="text-right">%</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngIf="cities_with_most_visits.length === 0">
						<td colspan="5">
							<div class="alert alert-info">
								<i class="fas fa-info-circle mr-2"></i>
								Nessun dato disponibile nel periodo selezionato
							</div>
						</td>
					</tr>
					<tr *ngFor="let row of cities_with_most_visits">
						<td>{{ row.provincia }}</td>
						<td>{{ row._id }}</td>
						<td class="text-right">{{ row.retailers }}</td>
						<td class="text-right">{{ row.count }}</td>
						<td class="text-right">{{ row.percentage_on_total | percent }}</td>
					</tr>
				</tbody>
				<tfoot class="bg-gradient-light text-italic">
					<td colspan="5">
						<a
							[ladda]="this.laddaExcel"
							class="text-success cursor-hand"
							(click)="this.downloadExcelWithComuni()"
						>
							<i class="fas fa-table"></i>
							Esporta Analisi Comuni in excel
						</a>
					</td>
				</tfoot>
			</table>
		</app-master-card>
	</div>

	<div class="col-12 col-xl-4">
		<h1 class="display-6 mb-0 mb-2">Timeline</h1>
		<div *ngIf="timeline.length === 0">
			<div class="alert alert-info">
				<i class="fas fa-info-circle mr-2"></i>
				Nessuna visita registrata, prova a cambiare periodo.
			</div>
		</div>

		<div #timelineDiv class="timeline" style="max-height: 700px; overflow-y: scroll">
			<ng-container *ngFor="let visits of timeline">
				<div class="time-label lead">
					<span class="bg-gradient-success">{{ visits.periodo }}</span>
				</div>
				<div *ngFor="let item of visits.visite" id="{{ item.id }}">
					<i class="fas fa-sim-card bg-gradient-info"></i>
					<div class="timeline-item bg-gradient-light elevation-1">
						<span class="time">
							<i class="far fa-clock mr-2"></i>
							{{ item.ora }}
						</span>
						<h3 class="timeline-header">
							<a *ngIf="item.id_dealer" [routerLink]="['/dealer', item.id_dealer]">
								{{ item.negozio }}
							</a>
							<span *ngIf="!item.id_dealer">{{ item.negozio }}</span>
							<small *ngIf="item.id_dealer" class="d-block">Partner già affiliato</small>
							<small *ngIf="!item.id_dealer" class="d-block">Potenziale partner</small>
							<small>
								Visita eseguita da:
								<strong>{{ item.agente_nome }}</strong>
							</small>
						</h3>
						<div class="timeline-body">
							<p class="lead font-italic"></p>
							<div [innerHTML]="item.note_visita | safeHtml"></div>

							<div
								*ngFor="let item of item.json_data | keyvalue"
								class="bg-gradient-white rounded p-2 mb-2 border"
							>
								<div class="text-md font-weight-bold">{{ item.key }}</div>
								<div>{{ item.value }}</div>
							</div>

							<div
								*ngIf="item.json_data && item.json_data.length === 0"
								class="text-muted font-italic"
							>
								Non ci sono note
							</div>

							<p *ngIf="item.affiliato === '1'" class="mt-2">
								<i class="fas fa-check-circle text-success"></i>
								Affiliato
							</p>
							<p *ngIf="item.attivita_chiusa === '1'" class="mt-2">
								<i class="fas fa-check-circle text-danger"></i>
								Attivita chiusa
							</p>

							<ng-container *ngFor="let result of item.results">
								<p *ngIf="result.evento === 'started-to-be-productive'">
									<i class="fas fa-cogs text-primary"></i>
									Ha iniziato a produrre
								</p>
								<p *ngIf="result.evento === 'increased-sales'">
									<i class="fas fa-chart-line text-primary"></i>
									Ha aumentato la produzione
								</p>
								<p *ngIf="result.evento === 'bought-plafond'">
									<i class="fas fa-coins text-primary"></i>
									Ha comprato il plafond
								</p>
								<p *ngIf="result.evento === 'bought-sim'">
									<i class="fas fa-sim-card text-primary"></i>
									Ha comprato le sim
								</p>
							</ng-container>

							<ng-container *isVendorEnabled="['Kena Mobile']">
								<hr />
								<ul>
									<li>
										Plafond segnalato:
										{{ item?.actual_plafond | currency : '€ ' }} | Reale:
										{{ item?.real_actual_plafond | currency : '€ ' }}
									</li>
									<li>
										Sim in magazzino: {{ item?.warehouse_sim }} | Reale:
										{{ item?.real_warehouse_sim }}
									</li>
								</ul>
							</ng-container>

							<ng-container *ngIf="item?.documents.length > 0">
								<hr />
								<p class="text-italic" translate>Foto del negozio:</p>
								<ul>
									<li *ngFor="let document of item?.documents; let i = index">
										<app-documents-modal #showModal [image]="true"></app-documents-modal>

										<div
											(click)="
												showModal.showDocument(
													environment.baseUrl +
														'/visite/get-picture?visit_id=' +
														item.id +
														'&id=' +
														document.id,
													true
												)
											"
											class="link"
										>
											Foto {{ i + 1 }}
										</div>
									</li>
								</ul>
							</ng-container>

							<button (click)="showModalQuestions(item.id)" class="btn btn-primary btn-block">
								Informazioni complete del questionario
							</button>

							<ng-container *ngFor="let reply of item.replies">
								<hr />
								<div [ngClass]="{ 'text-right': reply.direction === 'right' }">
									<p class="text-muted text-md font-italic">
										{{ reply.author_name }}
										<span class="float-right text-sm">
											{{ reply.created_at | amFromUnix | amDateFormat : 'DD-MM-YYYY H:mm' }}
										</span>
									</p>
									<p>{{ reply.note }}</p>
								</div>
							</ng-container>

							<ng-container *ngIf="item.showReply">
								<hr />
								<textarea [(ngModel)]="item.reply" class="form-control"></textarea>
								<button (click)="sendReply(item)" class="btn btn-sm btn-success mt-2">Invia</button>
							</ng-container>
						</div>
						<div class="timeline-footer">
							<i
								class="fas fa-eye mr-2 link"
								*ngIf="item.distance"
								(click)="item.expand_map = !item.expand_map"
							></i>
							<em>
								{{ item.citta }}, {{ item.provincia }}
								<ng-container *show="['master', 'masteragent', 'submaster', 'operator']">
									<span
										*ngIf="item.distance"
										[ngClass]="{ 'text-danger text-bold': item.distance > 500 }"
									>
										({{ item.distance }} metri di distanza)
									</span>
								</ng-container>
							</em>

							<a (click)="item.showReply = !item.showReply" class="float-right font-italic link">
								<i class="fa fa-reply"></i>
								Rispondi
							</a>
						</div>
						<div *ngIf="item.distance" [ngbCollapse]="!item.expand_map" class="timeline-footer">
							<agm-map
								[clickableIcons]="false"
								[latitude]="item.lat"
								[longitude]="item.lon"
								[showDefaultInfoWindow]="false"
								[styles]="defaultStyles"
								[zoom]="15"
								style="max-height: 300px"
							>
								<agm-marker
									[iconUrl]="mapPinOptions"
									[latitude]="item.lat"
									[longitude]="item.lon"
								></agm-marker>
								<agm-marker
									*ngIf="item.current_lat && item.current_lng"
									[iconUrl]="mapAgentPinOptions"
									[latitude]="item.current_lat"
									[longitude]="item.current_lng"
								></agm-marker>
							</agm-map>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>

<ng-template #modalNeverVisited let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" translate>Negozi mai visitati</h4>
	</div>
	<div class="modal-body p-0">
		<table class="table mb-0">
			<thead>
				<tr>
					<th>Negozio</th>
					<th>Provincia</th>
					<th>Città</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of retailersNeverVisited">
					<td>{{ row.ragsociale }}</td>
					<td>{{ row.province }}</td>
					<td>{{ row.city }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="modal-footer">
		<button (click)="c('Close click')" class="btn btn-secondary" type="button">Close</button>
	</div>
</ng-template>

<ng-template #modalResults let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" translate>Dettaglio</h4>
	</div>
	<div class="modal-body p-0">
		<table class="table mb-0">
			<thead>
				<tr>
					<th translate>Negozio</th>
					<th>Provincia</th>
					<th translate>Città</th>
					<th *ngIf="currentEvent === 'bought-plafond'">Plafond Acquistato</th>
					<th *ngIf="currentEvent === 'bought-sim'">Sim Acquistate</th>
					<th *ngIf="currentEvent === 'started-to-be-productive'">
						Giorni dopo la visita in cui ha iniziato a produrre
					</th>
					<th *ngIf="currentEvent === 'increased-sales'">Media Ordini Prima</th>
					<th *ngIf="currentEvent === 'increased-sales'">Media Ordini Dopo</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of resultsDetail">
					<td>{{ row.retailer.company }}</td>
					<td>{{ row.retailer.province }}</td>
					<td>{{ row.retailer.city }}</td>
					<td *ngIf="currentEvent === 'bought-plafond'">{{ row.dati.qty }}</td>
					<td *ngIf="currentEvent === 'bought-sim'">{{ row.dati.qty }}</td>
					<td *ngIf="currentEvent === 'started-to-be-productive'">
						{{ row.dati.days_since_visit }} giorni
					</td>
					<td *ngIf="currentEvent === 'increased-sales'">
						{{ row.dati.avg_sales_before }} ord./gg
					</td>
					<td *ngIf="currentEvent === 'increased-sales'">{{ row.dati.avg_sales_after }} ord./gg</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="modal-footer">
		<button (click)="c('Close click')" class="btn btn-secondary" type="button" translate>
			Close
		</button>
	</div>
</ng-template>

<!-- modale -->
<ng-template #questionsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title clamp-title">Informazioni complete del questionario</h4>
		<button (click)="modalService.dismissAll()" type="button" class="close" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<app-spinner *ngIf="!dealerData" status="loading"></app-spinner>

	<div class="modal-body" *ngIf="dealerData">
		<!-- Anagrafica -->
		<div class="card my-3" *ngIf="dealerData.type === 'dealer'">
			<div class="card-header bg-info">
				<h6 class="mb-0 font-weight-bold text-uppercase">Anagrafica Dealer</h6>
			</div>
			<div class="card-body">
				<dl class="row mb-0">
					<div class="col-md-6">
						<dl class="row mb-0">
							<dt class="col-sm-5 pr-0">Ragione Sociale:</dt>
							<dd class="col-sm-7 pl-0">{{ dealerData.dealerInfo.company }}</dd>
							<dt class="col-sm-5 pr-0">Nome:</dt>
							<dd class="col-sm-7 pl-0">{{ dealerData.dealerInfo.nome }}</dd>

							<dt class="col-sm-5 pr-0">Cognome:</dt>
							<dd class="col-sm-7 pl-0">{{ dealerData.dealerInfo.cognome }}</dd>

							<dt class="col-sm-5 pr-0">Attività chiusa:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.is_activity_closed ? 'Si' : 'No' }}
							</dd>

							<dt class="col-sm-5 pr-0">Posizione:</dt>
							<dd class="col-sm-7 pl-0">
								<b>Indirizzo:</b>
								{{ dealerData.dealerInfo.indirizzo }}
								<br />
								<b>Civico:</b>
								{{ dealerData.dealerInfo.civico }}
								<br />
								<b>Comune:</b>
								{{ dealerData.dealerInfo.localita }}
								<br />
								<b>CAP:</b>
								{{ dealerData.dealerInfo.cap }}
							</dd>
							<dt class="col-sm-5 pr-0">Operatori:</dt>
							<dd class="col-sm-7 pl-0">
								<ul class="p-0">
									<li *ngFor="let brand of dealerData.dealerInfo.brands">
										{{ brand }}
									</li>
								</ul>
							</dd>
						</dl>
					</div>
					<div class="col-md-6">
						<dl class="row mb-0">
							<dt class="col-sm-5 px-0">Telefono:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.dealerInfo.telefono }}
							</dd>

							<dt class="col-sm-5 px-0">Cellulare:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.dealerInfo.cellulare }}
							</dd>

							<dt class="col-sm-5 px-0">Email:</dt>
							<dd class="col-sm-7 pl-0">{{ dealerData.dealerInfo.email }}</dd>

							<dt class="col-sm-5 px-0">Giorni di chiusura:</dt>
							<dd class="col-sm-7 pl-0">
								<ul class="p-0">
									<li *ngFor="let day of dealerData.dealerInfo.closing_days">
										{{ day }}
									</li>
								</ul>
							</dd>
							<dt class="col-sm-5 px-0">Materiale esposto:</dt>
							<dd class="col-sm-7 pl-0">
								<ul class="p-0">
									<li *ngFor="let ad of dealerData.dealerInfo.material_exposed">
										{{ ad }}
									</li>
								</ul>
							</dd>

							<dd></dd>
						</dl>
					</div>
				</dl>
			</div>
		</div>
		<div class="card my-3" *ngIf="dealerData.type === 'prospect'">
			<div class="card-header bg-info">
				<h6 class="mb-0 font-weight-bold text-uppercase">Anagrafica Dealer</h6>
			</div>
			<div class="card-body">
				<dl class="row mb-0">
					<div class="col-md-6">
						<dl class="row mb-0">
							<dt class="col-sm-5 pr-0">Ragione Sociale:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.prospectInfo.company }}
							</dd>

							<dt class="col-sm-5 pr-0">Attività chiusa:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.is_activity_closed ? 'Si' : 'No' }}
							</dd>
						</dl>
					</div>
					<div class="col-md-6">
						<dl class="row mb-0">
							<dt class="col-sm-5 px-0">Comune:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.prospectInfo.localita }}
							</dd>

							<dt class="col-sm-5 px-0">Provincia:</dt>
							<dd class="col-sm-7 pl-0">
								{{ dealerData.prospectInfo.provincia }}
							</dd>
						</dl>
					</div>
				</dl>
			</div>
		</div>

		<!-- Domande e Risposte -->
		<div class="card">
			<div class="card-header bg-info">
				<h6 class="mb-0 font-weight-bold text-uppercase">Questionario</h6>
			</div>
			<div class="card-body">
				<div class="mb-3" *ngFor="let item of dealerData.json_data | keyvalue">
					<h6 class="font-weight-bold">{{ item.key }}</h6>
					<p>{{ item.value }}</p>
				</div>
			</div>
		</div>
	</div>

	<!--        <div class="modal-footer">-->
	<!--            <button type="button" class="btn btn-info"  (click)="downloadQuestionExcel()">Scarica questionario</button>-->
	<!--        </div>-->
</ng-template>
<!--  -->
