import { Component, OnInit } from '@angular/core';
import { RacesService } from '@services/races.service';
import { ExcelService } from '@services/excel.service';

@Component({
    selector: 'app-races-overview',
    templateUrl: './races-overview.component.html',
    styleUrls: ['./races-overview.component.css'],
})
export class RacesOverviewComponent implements OnInit {

    perPage = 10;
    currentPage = 1;

    originalRows = [];
    rows = [];

    filter = {
        dealer_id: '',
        masteragent_id: '',
        agent_id: '',
        channel: '',
        confirmation: '',
        month: (new Date()).getMonth() + 1,
        year: (new Date()).getFullYear(),
    };

    months = [
        { id: 1, name: 'Gennaio' },
        { id: 2, name: 'Febbraio' },
        { id: 3, name: 'Marzo' },
        { id: 4, name: 'Aprile' },
        { id: 5, name: 'Maggio' },
        { id: 6, name: 'Giugno' },
        { id: 7, name: 'Luglio' },
        { id: 8, name: 'Agosto' },
        { id: 9, name: 'Settembre' },
        { id: 10, name: 'Ottobre' },
        { id: 11, name: 'Novembre' },
        { id: 12, name: 'Dicembre' },
    ];

    years = [
        { id: 2024, name: '2024' },
        { id: 2025, name: '2025' },
        { id: 2026, name: '2026' },
        { id: 2027, name: '2027' },
    ];

    loading = false;

    constructor(private raceService: RacesService, private excelService: ExcelService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.loading = true;
        const params = {};

        if (this.filter.masteragent_id) {
            params['masteragent_id'] = this.filter.masteragent_id;
        }
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        if (this.filter.dealer_id) {
            params['dealer_id'] = this.filter.dealer_id;
        }
        if (this.filter.channel) {
            params['channel'] = this.filter.channel;
        }
        if (this.filter.confirmation) {
            params['confirmation'] = this.filter.confirmation;
        }

        params['month'] = this.filter.month;
        params['year'] = this.filter.year;

        this.raceService.getOverview(params).subscribe((response) => {
                this.originalRows = response;
                this.preparePagination();
                this.loading = false;
            },
            (error) => {
                this.loading = false;
            });
    }

    preparePagination() {
        this.rows = this.originalRows.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    }

    exportExcel() {
        let data = this.originalRows.map((row) => {
            return {
                'Login Code': row.login_code,
                'Negozio': row.company_name,
                'Sottocanale': row.subchannel,
                'District Manager': row.district,
                'Regional Manager': row.regional,
                'Gara': row.race,
                'Totale Ordini Validi': row.total_valid_orders,
                'Totale Ordini Annullati': row.total_invalid_orders,
                'Totale Ordini in Gara': row.total_race_orders,
                'Soglia Ordini': row.range,
                'Ordini alla prossima soglia': row.missing_range_orders,
                'Conferma Partecipazione': row.confirmed,
            };
        });
        this.excelService.exportAsExcelFile(data, 'Avanzamento Gare');
    }
}
