import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '@app/pages/main/main.component';
import { LoginComponent } from '@app/pages/login/login.component';
import { ProfileComponent } from '@app/pages/profile/profile.component';
import { DashboardComponent } from '@app/pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { NotFoundComponent } from '@app/pages/main/not-found/not-found.component';
import { ResetPasswordComponent } from '@app/pages/main/reset-password/reset-password.component';
import { environment } from '@environments/environment';
import { RoleGuard } from '@guards/role.guard';
import {
	ROLE_AGENT,
	ROLE_MASTER,
	ROLE_MASTERAGENT,
	ROLE_OPERATOR,
	ROLE_SUBMASTER,
	ROLE_SUBOPERATOR,
} from '@models/user';
import { MasterDashboardComponent } from '@app/pages/master-dashboard/master-dashboard.component';
import { MasterProduzionePersaComponent } from '@app/pages/master-produzione-persa/master-produzione-persa.component';
import { MasterClassificaComponent } from '@app/pages/master-classifica/master-classifica.component';
import { MasterConfrontoComponent } from '@app/pages/master-confronto/master-confronto.component';
import {
	MasterNuoveAffiliazioniComponent,
} from '@app/pages/master-nuove-affiliazioni/master-nuove-affiliazioni.component';
import { MasterEmailComponent } from '@app/pages/master-email/master-email.component';
import { MasterDealerDettaglioComponent } from '@app/pages/master-dealer-dettaglio/master-dealer-dettaglio.component';
import { AgentVisiteIndexComponent } from '@app/pages/agent-visite/agent-visite-index/agent-visite-index.component';
import { MasterVisiteIndexComponent } from '@app/pages/master-visite/master-visite-index/master-visite-index.component';
import {
	MasterVisiteDetailComponent,
} from '@app/pages/master-visite/master-visite-detail/master-visite-detail.component';
import { MasterKpiComponent } from '@app/pages/master-kpi/master-kpi.component';
import { CalendarioVisiteComponent } from '@app/pages/calendario-visite/calendario-visite.component';
import { MasterPlanningComponent } from '@app/pages/master-planning/master-planning.component';
import { DealerMapComponent } from '@app/pages/dealer-map/dealer-map.component';
import { ChangePasswordComponent } from '@app/pages/main/change-password/change-password.component';
import { PasswordGuard } from '@guards/password.guard';
import { NotFoundGuard } from '@guards/not-found.guard';
import { RacesComponent } from '@app/pages/races/races.component';
import { LoginVerificationComponent } from '@app/pages/login-verification/login-verification.component';
import { AgentGpsTrackingComponent } from '@app/pages/agent-gps-tracking/agent-gps-tracking.component';
import { DocumentsListComponent } from '@app/pages/documents/list/documents-list.component';
import { UserListComponent } from '@app/pages/user-list/user-list.component';
import { ReportVisiteComponent } from '@app/pages/report-visite/report-visite.component';
import { RegionalDetailComponent } from '@app/pages/regional-detail/regional-detail.component';
import { DistrictDetailComponent } from '@app/pages/district-detail/district-detail.component';
import { ConcorrenzaComponent } from '@app/pages/concorrenza/concorrenza.component';
import { WispComponent } from '@app/pages/wisp/wisp.component';
import { FormazioneRegionComponent } from '@app/pages/formazione-detail/formazione-region.component';
import { TradeMarketingRegionComponent } from '@app/pages/trade-marketing-detail/trade-marketing-region.component';
import { RacesOverviewComponent } from '@app/pages/races/races-overview/races-overview.component';

const ROUTES: Routes = [
	{
		path: '',
		component: DashboardComponent,
		data: {
			label: 'Home',
			menu: environment.production,
			icon: 'fa fa-tachometer-alt',
		},
	},
	// {
	//     path: 'retailers/list',
	//     component: RetailerManagementComponent,
	//     canActivate: [RoleGuard],
	//     data: {
	//         label: 'Gestione Negozi',
	//         menu: environment.production,
	//         icon: 'fa fa-user-cog',
	//         roles: [ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTER, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER],
	//     },
	// },
	{
		path: 'retailers/list',
		component: UserListComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Gestione Partner',
			menu: environment.production,
			icon: 'fa fa-user-cog',
			roles: [
				ROLE_OPERATOR,
				ROLE_AGENT,
				ROLE_MASTER,
				ROLE_OPERATOR,
				ROLE_MASTERAGENT,
				ROLE_SUBMASTER,
				ROLE_SUBOPERATOR,
			],
		},
	},
	{
		path: 'affiliations/dashboard',
		component: MasterNuoveAffiliazioniComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Analisi Aperture',
			menu: environment.production,
			icon: 'fa fa-chart-line',
			roles: [
				ROLE_MASTER,
				ROLE_OPERATOR,
				ROLE_AGENT,
				ROLE_MASTERAGENT,
				ROLE_SUBMASTER,
				ROLE_OPERATOR,
			],
		},
	},
	// {
	//     path: 'affiliations/lista',
	//     component: AffilationsComponent,
	//     canActivate: [RoleGuard],
	//     data: {
	//         label: 'Contratti Aperture',
	//         menu: environment.production,
	//         icon: 'fa fa-list',
	//         roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_AGENT, ROLE_OPERATOR],
	//     },
	// },
	// {
	//     path: 'affiliations/bulk',
	//     component: BulkAssignDealerComponent,
	//     canActivate: [RoleGuard],
	//     data: {
	//         label: 'Assegna Key Account',
	//         menu: environment.production,
	//         icon: 'fa fa-user-plus',
	//         roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_OPERATOR],
	//     },
	// },
	{
		path: 'dealer-map',
		component: DealerMapComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Map analytics',
			menu: environment.production,
			icon: 'fa fa-map-marked-alt',
			roles: [
				ROLE_AGENT,
				ROLE_MASTER,
				ROLE_OPERATOR,
				ROLE_MASTERAGENT,
				ROLE_SUBMASTER,
				ROLE_OPERATOR,
			],
		},
	},
	{
		path: 'races',
		component: RacesComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Gare',
			menu: environment.production,
			icon: 'fa fa-user',
			roles: [ROLE_MASTER],
		},
	},
	{
		path: 'races/overview',
		component: RacesOverviewComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Avanzamento Gare',
			menu: environment.production,
			icon: 'fa fa-chart-line',
			roles: [ROLE_MASTER, ROLE_MASTERAGENT, ROLE_AGENT],
		},
	},
	// {
	//     path: 'group',
	//     component: GroupComponent,
	//     data: {
	//         label: 'Gruppi di negozi',
	//         menu: environment.production,
	//         icon: 'fa fa-users',
	//         roles: [ROLE_OPERATOR, ROLE_MASTER],
	//     },
	// },
	{
		path: 'dashboard',
		component: MasterDashboardComponent,
		canActivate: [RoleGuard],
		data: {
			label: '',
			menu: false,
			icon: '',
			roles: [
				ROLE_MASTER,
				ROLE_OPERATOR,
				ROLE_AGENT,
				ROLE_MASTERAGENT,
				ROLE_SUBMASTER,
				ROLE_OPERATOR,
				ROLE_SUBOPERATOR,
			],
		},
	},
	{
		path: 'planning',
		component: MasterPlanningComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Planning',
			menu: environment.production,
			icon: 'fa fa-user',
			roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_AGENT],
		},
	},
	{
		path: 'kpi',
		component: MasterKpiComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'KPI & Target',
			menu: environment.production,
			icon: 'fa fa-bullseye',
			roles: [ROLE_AGENT, ROLE_MASTER, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER],
		},
	},
	{
		path: 'gps-tracking',
		component: AgentGpsTrackingComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'GPS tracking',
			menu: environment.production,
			icon: 'fa fa-map-marked-alt',
			roles: [
				ROLE_AGENT,
				ROLE_MASTER,
				ROLE_MASTERAGENT,
				ROLE_SUBMASTER,
				ROLE_OPERATOR,
				ROLE_SUBOPERATOR,
			],
		},
	},
	{
		path: 'visite',
		canActivate: [RoleGuard],
		data: {
			label: 'Visite',
			menu: environment.production,
			icon: 'fa fa-route',
			roles: [ROLE_AGENT, ROLE_SUBOPERATOR, ROLE_OPERATOR],
		},
		children: [
			{
				path: 'gestione',
				component: AgentVisiteIndexComponent,
				canActivate: [RoleGuard],
				data: {
					label: 'Gestione Visite',
					menu: environment.production,
					icon: 'fa fa-map-marker-alt',
					roles: [ROLE_AGENT, ROLE_SUBOPERATOR, ROLE_OPERATOR],
				},
			},
			{
				path: 'panoramica',
				component: MasterVisiteDetailComponent,
				canActivate: [RoleGuard],
				data: {
					label: 'Panoramica Visite',
					menu: environment.production,
					icon: 'fa fa-wrench',
					roles: [ROLE_AGENT, ROLE_OPERATOR],
				},
			},
			{
				path: 'panoramica/:visit_id',
				component: MasterVisiteDetailComponent,
				canActivate: [RoleGuard],
				data: {
					label: null,
					menu: false,
					icon: null,
					roles: [ROLE_AGENT, ROLE_OPERATOR],
				},
			},
			{
				path: 'calendario',
				component: CalendarioVisiteComponent,
				canActivate: [RoleGuard],
				data: {
					label: 'Calendario Visite',
					menu: environment.production,
					icon: 'fa fa-calendar',
					roles: [ROLE_AGENT, ROLE_OPERATOR],
				},
			},
		],
	},
	{
		path: 'visite-riepilogo',
		component: MasterVisiteIndexComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Riepilogo Visite',
			menu: environment.production,
			icon: 'fa fa-route',
			roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_SUBOPERATOR],
		},
	},
	{
		path: 'visite-riepilogo/:id',
		component: MasterVisiteDetailComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Panoramica Visite',
			menu: false,
			icon: 'fa fa-wrench',
			roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_SUBOPERATOR],
		},
	},
	{
		path: 'visite-riepilogo/:id/:visit_id',
		component: MasterVisiteDetailComponent,
		canActivate: [RoleGuard],
		data: {
			label: '',
			menu: false,
			icon: '',
			roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_SUBOPERATOR],
		},
	},
	{
		path: 'report-visite',
		component: ReportVisiteComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Report Visite',
			menu: environment.production,
			icon: 'fa fa-chart-line',
			roles: [
				ROLE_AGENT,
				ROLE_MASTER,
				ROLE_OPERATOR,
				ROLE_MASTERAGENT,
				ROLE_SUBMASTER,
				ROLE_SUBOPERATOR,
			],
		},
	},
	{
		path: 'brain',
		component: MasterEmailComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Automatismi',
			menu: environment.production,
			icon: 'fa fa-brain',
			roles: [ROLE_MASTER],
		},
	},
	{
		path: 'produzione-persa',
		component: MasterProduzionePersaComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Produzione Persa',
			menu: environment.production,
			icon: 'fa fa-search-minus',
			roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER],
		},
	},
	// {
	//     path: 'analisi-fasce',
	//     component: MasterAnalisiFasceComponent,
	//     canActivate: [RoleGuard],
	//     data: {
	//         label: 'Analisi Fasce',
	//         menu: environment.production,
	//         icon: 'fa fa-list-ol',
	//         roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_MASTERAGENT, ROLE_SUBMASTER, ROLE_OPERATOR],
	//     },
	// },
	{
		path: 'classifica',
		component: MasterClassificaComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Analisi Produzione',
			menu: environment.production,
			icon: 'fa fa-sitemap',
			roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER],
		},
	},
	{
		path: 'confronto',
		component: MasterConfrontoComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Confronto',
			menu: environment.production,
			icon: 'fa fa-random',
			roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_SUBMASTER],
		},
	},
	{
		path: 'concorrenza',
		component: ConcorrenzaComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Concorrenza',
			menu: environment.production,
			icon: 'fa fa-chart-bar',
			roles: [
				ROLE_OPERATOR,
				ROLE_AGENT,
				ROLE_MASTER,
				ROLE_MASTERAGENT,
				ROLE_SUBMASTER,
				ROLE_SUBOPERATOR,
			],
		},
	},
	{
		path: 'rilevazione-wisp',
		component: WispComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Rilevazione WISP',
			menu: environment.production,
			icon: 'fa fa-broadcast-tower',
			roles: [
				ROLE_OPERATOR,
				ROLE_AGENT,
				ROLE_MASTER,
				ROLE_MASTERAGENT,
				ROLE_SUBMASTER,
				ROLE_SUBOPERATOR,
			],
		},
	},
	{
		path: 'dealer/:id',
		component: MasterDealerDettaglioComponent,
		canActivate: [RoleGuard],
		data: {
			label: null,
			menu: false,
			icon: null,
			roles: [
				ROLE_MASTER,
				ROLE_OPERATOR,
				ROLE_AGENT,
				ROLE_SUBOPERATOR,
				ROLE_MASTERAGENT,
				ROLE_SUBMASTER,
			],
		},
	},

	{
		path: 'district/:id',
		component: DistrictDetailComponent,
		canActivate: [RoleGuard],
		data: {
			label: null,
			menu: false,
			icon: null,
			roles: [ROLE_MASTER],
		},
	},
	{
		path: 'formazione-regioni/:id',
		component: FormazioneRegionComponent,
		canActivate: [RoleGuard],
		data: {
			label: null,
			menu: false,
			icon: null,
			roles: [ROLE_MASTER],
		},
	},
	{
		path: 'trade-marketing-regioni/:id',
		component: TradeMarketingRegionComponent,
		canActivate: [RoleGuard],
		data: {
			label: null,
			menu: false,
			icon: null,
			roles: [ROLE_MASTER],
		},
	},
	{
		path: 'regional/:id',
		component: RegionalDetailComponent,
		canActivate: [RoleGuard],
		data: {
			label: null,
			menu: false,
			icon: null,
			roles: [ROLE_MASTER],
		},
	},
	// {
	//     path: 'e-mail-campaign',
	//     component: EMailCampaignComponent,
	//     canActivate: [RoleGuard],
	//     data: {
	//         label: 'Mailchimp',
	//         menu: environment.production,
	//         icon: 'far fa-envelope',
	//         roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_OPERATOR],
	//     },
	// },
	// {
	//     path: 'pbx',
	//     canActivate: [RoleGuard],
	//     data: {
	//         label: 'PBX',
	//         menu: environment.production,
	//         icon: 'fa fa-list',
	//         roles: [ROLE_OPERATOR, ROLE_MASTER],
	//     },
	//     children: [
	//         {
	//             path: 'campaign',
	//             component: PbxCampaignComponent,
	//             data: {
	//                 label: 'Campaigns',
	//                 menu: environment.production,
	//                 icon: 'fa fa-headset '
	//             },
	//         },
	//         {
	//             path: 'questions',
	//             component: PbxQuestionsComponent,
	//             data: {
	//                 label: 'Questions',
	//                 menu: environment.production,
	//                 icon: 'far fa-question-circle',
	//             },
	//         }
	//     ]
	// },
	{
		path: 'documenti',
		component: DocumentsListComponent,
		canActivate: [RoleGuard],
		data: {
			label: 'Documenti',
			menu: environment.production,
			icon: 'fa fa-folder-open',
			roles: [ROLE_MASTER, ROLE_OPERATOR, ROLE_AGENT, ROLE_MASTERAGENT, ROLE_SUBMASTER],
		},
	},
	{
		path: 'profile',
		component: ProfileComponent,
		data: {
			label: '',
			menu: false,
			icon: '',
		},
	},
	{
		path: 'profile/:id',
		component: ProfileComponent,
		data: {
			label: '',
			menu: false,
			icon: '',
		},
	},
	{
		path: 'pagina-non-trovata',
		component: NotFoundComponent,
		canActivate: [NotFoundGuard],
		data: {
			label: '',
			menu: false,
			icon: '',
		},
	},
];

const APP_ROUTES: Routes = [
	{
		path: '',
		component: MainComponent,
		canActivate: [AuthGuard],
		children: ROUTES,
	},
	{
		path: 'login',
		component: LoginComponent,
		canLoad: [
			{
				menu: false,
				icon: '',
			},
		],
	},
	{
		path: 'reset-password',
		component: ResetPasswordComponent,
		canLoad: [
			{
				menu: false,
				icon: '',
			},
		],
	},
	{
		path: 'login-verification',
		component: LoginVerificationComponent,
		canActivate: [PasswordGuard],
		canLoad: [
			{
				menu: false,
				icon: '',
			},
		],
	},
	{
		path: 'change-password',
		component: ChangePasswordComponent,
		canActivate: [PasswordGuard],
		canLoad: [
			{
				menu: false,
				icon: '',
			},
		],
	},
	{ path: '**', redirectTo: '/pagina-non-trovata' },
];

@NgModule({
	imports: [RouterModule.forRoot(APP_ROUTES, { useHash: false })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
