<app-master-header
	cardClass="bg-gradient-light"
	image="assets/img/flats/placeholder.png"
	title="Programmazione Visite"
>
	<p class="lead">
		In questa sezione puoi programmare le visite sul calendario. Successivamente dovrai solo
		confermarle nel piano visite giornaliero.
	</p>
</app-master-header>

<div class="row">
	<div class="col-xl-12 col-xxl-3">
		<div class="card bg-gradient-light">
			<div class="card-body">
				<legend>Pianifica una nuova visita</legend>
				<form [formGroup]="form" (ngSubmit)="save()">
					<div class="form-group">
						<label>Seleziona un dealer</label>
						<app-negozio-select
							[small]="true"
							[isFunctional]="'0'"
							[disabled]="disabledIdRetailer"
							bindLabel="nameWithLocation"
							(onChange)="assignment($event)"
						></app-negozio-select>
						<val-errors controlName="id_dealer"></val-errors>
					</div>
					<div class="form-group">
						<label>Seleziona la data</label>
						<input
							class="form-control form-control-sm"
							placeholder="gg-mm-aaaa"
							ngbDatepicker
							#data="ngbDatepicker"
							formControlName="date"
							(click)="data.toggle()"
						/>
					</div>
					<val-errors controlName="date"></val-errors>
					<button
						class="btn btn-block btn-success btn-sm"
						[disabled]="this.disabledIdRetailer"
						type="submit"
					>
						Salva
					</button>
				</form>
			</div>
		</div>

		<div class="card bg-gradient-light">
			<div class="card-body">
				<legend>Pianifica una nuova visita</legend>
				<form [formGroup]="form" (ngSubmit)="save()">
					<div class="form-group">
						<label>Scrivi il nome del dealer non affiliato</label>
						<input
							type="text"
							class="form-control form-control-sm"
							formControlName="retailer"
							[disableControl]="disabledRetailer"
						/>
						<val-errors controlName="retailer"></val-errors>
					</div>
					<div class="form-group">
						<label>Seleziona la data</label>
						<input
							class="form-control form-control-sm"
							placeholder="gg-mm-aaaa"
							ngbDatepicker
							#dateTwo="ngbDatepicker"
							formControlName="date"
							(click)="dateTwo.toggle()"
						/>
					</div>
					<val-errors controlName="date"></val-errors>
					<button
						class="btn btn-block btn-success btn-sm"
						[disabled]="this.disabledRetailer"
						type="submit"
					>
						Salva
					</button>
				</form>
			</div>
		</div>
	</div>
	<div class="col-xl-12 col-xxl-9">
		<div class="card bg-gradient-light">
			<div class="card-body p-0">
				<ngx-spinner
					bdColor="rgba(0, 0, 0, 0.8)"
					size="default"
					color="#fff"
					type="square-jelly-box"
					name="calendar"
					[fullScreen]="false"
				>
					<p style="color: white">Caricamento...</p>
				</ngx-spinner>
				<full-calendar [options]="calendarOptions" #calendar></full-calendar>
			</div>
		</div>
	</div>
</div>

<swal
	#deleteSwal
	title="Vuoi cancellare questa visita?"
	text="Sei sicuro di voler cancellare questa visita programmata?"
	[showCancelButton]="true"
	icon="warning"
	confirmButtonText="Si"
	cancelButtonText="Annulla"
	[customClass]="{
		confirmButton: 'btn btn-danger',
		cancelButton: 'btn btn-default'
	}"
	(confirm)="deleteEvent()"
></swal>

<app-modal #removeEvent>
	<p class="lead">Vuoi cancellare questa visita programmata?</p>
	<button class="btn btn-block btn-danger" (click)="delete()">Si, cancella!</button>
</app-modal>
