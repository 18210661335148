import { VisiteService } from '@services/visite.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Calendar } from '@fullcalendar/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { AgenteService } from '@services/agente.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AgentVisitsService } from '@services/agent-visits.service';

@Component({
	selector: 'app-calendario-visite',
	templateUrl: './calendario-visite.component.html',
	styleUrls: ['./calendario-visite.component.scss'],
})
export class CalendarioVisiteComponent implements OnInit {
	@ViewChild('deleteSwal')
	public readonly deleteSwal!: SwalComponent;

	@ViewChild('calendar') calendarComponent: FullCalendarComponent;
	events = [];
	calendarOptions: CalendarOptions = {
		initialView: 'dayGridMonth',
		dateClick: this.handleDateClick.bind(this), // bind is important!
		eventClick: this.handleEventClick.bind(this),
		eventDrop: this.handleEventDrop.bind(this),
		events: this.events,
		locale: 'it',
		editable: true,
		eventDisplay: 'block',
		displayEventTime: false,
		customButtons: {
			next: {
				text: 'new',
				click: this.nextMonth.bind(this),
			},
			prev: {
				text: 'prev',
				click: this.prevMonth.bind(this),
			},
			today: {
				text: 'today',
				click: this.currentMonth.bind(this),
			},
		},
	};
	form: FormGroup;
	currentDate = moment().format('YYYY-MM-DD');
	disabledIdRetailer = false;
	disabledRetailerKena = false;
	disabledRetailer = false;

	currentEvent: any;

	constructor(
		public service: VisiteService,
		public agenteService: AgenteService,
		private fb: FormBuilder,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private agentVisitsService: AgentVisitsService
	) {
		const name = Calendar.name;
		this.retailerValidator = this.retailerValidator.bind(this);
	}

	ngOnInit(): void {
		this.refresh();

		this.form = this.fb.group(
			{
				id_dealer: [{ value: null, disabled: this.disabledIdRetailer }],
				retailer: [{ value: null, disabled: this.disabledRetailerKena }],
				retailer_kena: [{ value: null, disabled: this.disabledRetailer }],
				date: [null, [Validators.required]],
			},
			{ validator: this.retailerValidator }
		);
	}

	retailerValidator(group: FormGroup): { [s: string]: boolean } {
		if (group) {
			let idRetailerVal = group.controls['id_dealer'].value;
			let retailerVal = group.controls['retailer'].value;

			if (!idRetailerVal && !retailerVal) {
				this.disabledIdRetailer = false;
				this.disabledRetailer = false;
			}
			if (idRetailerVal && !retailerVal) {
				this.disabledIdRetailer = false;
				this.disabledRetailer = true;
			}
			if (!idRetailerVal && retailerVal) {
				this.disabledIdRetailer = true;
				this.disabledRetailer = false;
			}

			if (group.controls['id_dealer'].value || group.controls['retailer'].value) {
				return null;
			}
		}
		return { error: true };
	}

	handleEventDrop(arg) {
		this.spinner.show('calendar');
		const body = {
			datetime: moment(arg.event.start).format('YYYY-MM-DD'),
		};
		this.agentVisitsService.putSchedule(arg.event.id, body).subscribe(
			(data: any) => {
				this.spinner.hide('calendar');
				this.toastr.success(`Evento spostato con successo`, 'Notifica');
			},
			(err: any) => {
				this.refresh();
				this.toastr.error(`Si è verificato un'errore con lo spostamento`, 'Errore');
			}
		);
	}

	handleDateClick(arg) {
		// console.log(arg);
	}

	deleteEvent() {
		this.agentVisitsService.deleteSchedule(this.currentEvent.id).subscribe(
			(data: any) => {
				this.currentEvent.remove();
				this.toastr.success(`Evento eliminato con successo`, 'Notifica');
			},
			(err: any) => {
				this.toastr.error(`Si è verificato un'errore con l'eliminazione`, 'Errore');
			}
		);
	}

	handleEventClick(arg) {
		this.deleteSwal.fire();
		this.currentEvent = arg.event;
	}

	nextMonth(arg) {
		const calendarApi = this.calendarComponent.getApi();
		calendarApi.next();
		this.currentEvent = arg.event;
		this.currentDate = moment(this.currentDate).add(1, 'M').format('YYYY-MM-DD');
		this.refresh();
	}

	prevMonth(arg) {
		const calendarApi = this.calendarComponent.getApi();
		calendarApi.prev();
		this.currentEvent = arg.event;
		this.currentDate = moment(this.currentDate).add(-1, 'M').format('YYYY-MM-DD');
		this.refresh();
	}

	currentMonth(arg) {
		const calendarApi = this.calendarComponent.getApi();
		calendarApi.today();
		this.currentEvent = arg.event;
		this.currentDate = moment().format('YYYY-MM-DD');
		this.refresh();
	}

	refresh() {
		this.spinner.show('calendar');
		const params = {};
		if (this.currentDate) {
			params['month'] = moment(this.currentDate).format('MM');
			params['year'] = moment(this.currentDate).format('YYYY');
		}
		this.service.salesAgentPlanning(null, params).subscribe((data) => {
			this.events = data;
			this.calendarOptions.events = this.events;
			this.spinner.hide('calendar');
		});
	}

	assignment($event: any) {
		this.form.patchValue({ id_dealer: $event });
	}

	save() {
		console.log('Form |', this.form.getRawValue());
		this.agentVisitsService.postSchedule(this.form.value).subscribe((data) => {
			if (data) {
				console.log('Response |', data);
				this.toastr.success(`Evento creato con successo`, 'Notifica');
				this.form.reset();
				this.refresh();
			}
		}),
			(err) => {
				this.toastr.error(err, 'Errore');
			};
	}

	delete() {}
}
