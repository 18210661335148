import {VisiteService} from '@services/visite.service';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {DashboardService} from '@services/dashboard.service';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {CalendarOptions} from '@fullcalendar/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from '@services/authentication.service';
import {AgentVisitsService} from '@services/agent-visits.service';
import {environment} from '@environments/environment';
import {ViewportScroller} from '@angular/common';
import {MapTypeStyle} from '@agm/core/services/google-maps-types';
import {DataBuilderModalService} from '@services/data-builder-modal.service';
import {RequestService} from '@services/request.service';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-master-visite-detail',
    templateUrl: './master-visite-detail.component.html',
    styleUrls: ['./master-visite-detail.component.scss'],
})
export class MasterVisiteDetailComponent implements OnInit {
    @ViewChild('questionsModal') private questionsModal;
    busy: Subscription;
    id: any;
    visit_id = null;

    currentImageIndex = null; // Indice dell'immagine corrente
    visitImages = [];

    get currentVisitImage() {
        if(this.currentImageIndex !== null && this.currentImageIndex < this.visitImages.length){
            return this.visitImages[this.currentImageIndex];
        }
        return null;
    }

    selectedDateRange = {
        from: '',
        to: '',
    };

    filterForm = {
        dateRange: '',
    };

    sales_agent = 'Caricamento...';
    global: any;
    kpi: any;
    provinces = [];
    cities_with_most_visits = [];
    timeline = [];
    retailersNeverVisited = [];
    resultsDetail = [];
    currentEvent;

    baseAwsUrl = environment.baseAwsUrl;

    defaultView = true;

    calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        events: [],
        locale: 'it',
    };

    @ViewChild('timelineDiv') public timelineElement: ElementRef;

    defaultStyles: MapTypeStyle[] = [
        {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
                {visibility: 'off'}
            ]
        }
    ];

    mapPinOptions = {
        url: 'assets/img/flats/house_2.png',
        scaledSize: {
            width: 30,
            height: 30
        }
    };

    mapAgentPinOptions = {
        url: 'assets/img/flats/agent.png',
        scaledSize: {
            width: 30,
            height: 30
        }
    };
    laddaExcel = false;
    dealerData = null;

    constructor(
        private auth: AuthenticationService,
        private service: VisiteService,
        private modalService: NgbModal,
        private spinner: NgxSpinnerService,
        private dateRangePickerOptions: DaterangepickerConfig,
        private dashboardService: DashboardService,
        private requestService: RequestService,
        private agentVisitsService: AgentVisitsService,
        public dbm: DataBuilderModalService,
        public route: ActivatedRoute,
        private scroller: ViewportScroller,
        private authService: AuthenticationService,

    ) {
        this.id = this.route.snapshot.paramMap.get('id');
        const visit_id = this.route.snapshot.paramMap.get('visit_id');

        if (visit_id) {
            this.visit_id = visit_id;
        }

        if (!this.id) {
            this.id = this.auth.currentUserValue.profile.id;
        }

        this.dateRangePickerOptions.settings.locale.format = 'DD/MM/YYYY';
        this.setDefaultRange();

    }

    ngOnInit() {
    }

    refresh() {
        this.busy = this.service
            .salesAgentOverview(
                this.selectedDateRange.from,
                this.selectedDateRange.to,
                this.id
            )
            .subscribe((data) => {
                this.global = data.overview;
                this.provinces = data.provinces;
                this.cities_with_most_visits = data.cities_with_most_visits;
                this.timeline = data.timeline;
                console.log("timeline", this.timeline);
                this.kpi = data.kpi;
                this.sales_agent = "Dettaglio Visite di " + data.sales_agent;

                if (this.visit_id) {
                    setTimeout(() => {
                        // console.log(document.getElementById(this.visit_id));
                        document.getElementById(this.visit_id).scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'nearest'
                        });
                    }, 1000);
                }

                for(let i=0; i < this.timeline.length; i++){
                    const timelineVisits = this.timeline[i].visite;
                    for(let j = 0; j < timelineVisits.length; j++){
                        const visit = timelineVisits[j];
                        if(visit.documents && visit.documents.length > 0){
                            for (let k = 0; k < visit.documents.length; k++) {
                                let visitImage = {
                                    shop_name: visit.negozio,
                                    city: visit.citta,
                                    province: visit.provincia,
                                    date: visit.periodo,
                                    time: visit.ora,
                                    id: visit.id,
                                    file_path: this.environment.baseUrl + 'visite/get-picture?visit_id=' + visit.id + '&id=' + visit.documents[k].id + '&access_token=' + this.authService.currentUserToken,
                                }
                                this.visitImages.push(visitImage);
                            }
                        }
                    }
                }

                if(this.visitImages.length > 0){
                    this.currentImageIndex = 0;
                }

                console.log(this.visitImages);
            });

        this.spinner.show('calendar');
        this.service.salesAgentCalendar('2021-01', this.id).subscribe((data) => {
            this.calendarOptions.events = data;
            this.spinner.hide('calendar');
        });
    }

    
    setDefaultRange() {
        this.setSelectedDateRange(moment().startOf('month'), moment());
    }

    setSelectedDateRange(from: Moment, to: Moment) {
        if (from.isSame(moment().startOf('month')) && to.isSame(moment())) {
            this.defaultView = true;
        } else {
            this.defaultView = false;
        }

        const selectedDateRange = this.getDateString(from, to);
        this.filterForm.dateRange = selectedDateRange;
        this.refresh();
    }

    showRetailersNeverVisited() {
        this.dbm.show('visites/retailers-never-visited/' + this.id, 'Negozi mai visitati');
    }

    showResultsDetail(modal, event) {
        this.busy = this.service
            .resultsDetail(
                this.selectedDateRange.from,
                this.selectedDateRange.to,
                this.id,
                event
            )
            .subscribe((data) => {
                this.currentEvent = event;
                this.resultsDetail = data;
                this.modalService.open(modal, {size: 'lg'});
            });
    }

    sendReply(item: any) {
        this.busy = this.agentVisitsService
            .postResponse({
                id_visita: item.id,
                testo: item.reply,
            })
            .subscribe((data) => {
                this.refresh();
            });
    }

    downloadExcel() {
        this.laddaExcel = true;
        this.service
            .salesAgentOverview(
                this.selectedDateRange.from,
                this.selectedDateRange.to,
                this.id, true
            )
            .subscribe((data) => {
                    this.requestService.downloadFile(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                    this.laddaExcel = false;
                },
                (error) => {
                    this.laddaExcel = false;
                });
    }

    downloadExcelWithProvinces() {
        let rows = this.provinces;

        let output = rows.map((row) => {
            return {
                'Provincia': row.name,
                'PDV Totali': row.total_retailers,
                'PDV Visitati': row.our_retailer_visits,
                'Percentuale %': (row.percentage * 100).toFixed(2) + "%",
            };
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'export-analisi-provincia.xlsx');
    };

    downloadExcelWithComuni(){
        let rows = this.cities_with_most_visits;

        let output = rows.map((row) => {
            return {
                'Provincia': row.provincia,
                'Comune': row._id,
                'PDV Totali': row.retailers,
                'Visite Totali': row.count,
                'Percentuale %': (row.percentage_on_total * 100).toFixed(2) + "%",
            };
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'export-analisi-comuni.xlsx');
    };


    private getDateString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDateRange.from = start.format('YYYY-MM-DD');
        this.selectedDateRange.to = end.format('YYYY-MM-DD');
        return (
            start.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            end.format(localDateFormat)
        );
    }

    protected readonly environment = environment;

    previousImage() {
        if(this.currentImageIndex > 0){
            this.currentImageIndex--;
        }
    }

    nextImage() {
        if(this.currentImageIndex < this.visitImages.length - 1){
            this.currentImageIndex++;
        }
    }

    showModalQuestions(id_visit) {
        this.modalService.open(this.questionsModal,{ size: 'lg', scrollable: true});

        const params = {
            visitId: id_visit,
        }
        this.dealerData = null;
        this.agentVisitsService
            .getQuestionsDetail(params)
            .subscribe((data) => {
                this.dealerData = data;
            });
    }
}
